import React from "react";
import "./Imprint.css";
import { Link } from "react-router-dom";

const Imprint = ({ language, theme }) => {
  const imprintArray = [
    "Kanzowstraße 18, 10439 Berlin",
    "Telefon: +49 (0)176 / 789 33 4 55",
    "mailto:impressum@timonschell.com?subject=impressum",
  ];

  return (
    <div className="imprint">
      <fieldset
        className="imprintField"
        data-aos="zoom-in-up"
        data-aos-duration="1500"
      >
        <legend>
          <h1 className="imprintLegend unselectable">
            {language ? "Imprint" : "Impressum"}
          </h1>
        </legend>

        <div className="imprintOuter">
          <div className="imprintDiv unselectable">
            <h3>Timon Schell</h3>
            <p>{language ? "Web Developer" : "Webentwickler"}</p>
            <p>{imprintArray[0]}</p>
            {/* <p>{imprintArray[1]}</p> */} {/* Telefonnummer */}
            <a href={imprintArray[2]}>
              {language ? "Send email" : "Email senden"}
            </a>
            <p>
              {language ? (
                <>
                  Alternatively, you can reach me via the&nbsp;
                  <a href="/#contact">contact form</a>
                </>
              ) : (
                <>
                  Alternativ erreichen Sie mich über das&nbsp;
                  <a href="/#contact">Kontaktformular</a>
                </>
              )}
            </p>
            <p>
              {language
                ? "\nResponsible person within the meaning of § 55 para. 2 RStV:\n\n"
                : "\nVerantwortlich für den Inhalt nach § 55 Abs. 2 RStV:\n\n"}
            </p>
            <p>Timon Schell</p>
            <p>{imprintArray[0]}</p>
          </div>
        </div>
      </fieldset>
      <Link to="/" className="imprintButton">
        {language ? "Back" : "Zurück"}
      </Link>
    </div>
  );
};

export default Imprint;
